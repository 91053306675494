<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-04-22 13:39:24
 * @ Description: Fixer's requested dashboard page in the user's professional service dashboard section of the app.
 -->

<template>
    <div>
        <v-row>
            <v-col
                cols="12"
                style="position: relative;"
            >
                <!-- Filters -->
                <v-row
                    align="center"
                >
                    <v-col
                        class="flex-shrink-1 flex-grow-0 os-15-sb"
                        v-if="$vuetify.breakpoint.mdAndUp"
                    >
                        Filter:
                    </v-col>
                    <v-col
                        class="flex-grow-1 flex-shrink-0"
                    >
                        <v-row>
                            <v-col
                                :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                            >
                                <v-select
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="os-13-r"
                                    background-color="white"
                                    placeholder="Industry"
                                    hide-details
                                    :items="industries"
                                    return-object
                                    item-text="name"
                                    item-value="id"
                                    v-model="searchIndustry"
                                    clearable
                                    @click:clear="searchIndustry = { id: null }"
                                />
                            </v-col>
                            <!-- <v-col
                                :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                            >
                                <v-select
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="os-13-r"
                                    background-color="white"
                                    placeholder="Job Title"
                                    hide-details
                                    return-object
                                    item-text="name"
                                    item-value="id"
                                    v-model="searchJobTitle"
                                    clearable
                                    @click:clear="searchJobTitle = { id: null }"
                                />
                            </v-col> -->
                            <v-col
                                :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                            >
                                <v-select
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="os-13-r"
                                    background-color="white"
                                    placeholder="Service Location"
                                    hide-details
                                    :items="serviceLocations"
                                    return-object
                                    item-text="name"
                                    item-value="id"
                                    v-model="searchServiceLocation"
                                    clearable
                                    @click:clear="clearServiceLocation"
                                />
                            </v-col>
                            <v-col
                                :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                            >
                                <general-google-autocomplete-address-component
                                    @googlePlaceSelected="googlePlaceSelected"
                                    @addressChanged="addressChanged"
                                    :address="address"
                                    :placeholder="locationPlaceholder"
                                    :hideDetails="true"
                                    :clearable="true"
                                    :disabled="!canEnterLocation"
                                    class="mt-n2"
                                />
                            </v-col>
                            <v-col
                                :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                            >
                                <v-text-field
                                    dense
                                    hide-details
                                    outlined
                                    color="#2E567A"
                                    placeholder="Search..."
                                    class="os-13-r"
                                    background-color="white"
                                    v-model="mainSearch"
                                    clearable
                                    @click:clear="clearMainSearch"
                                >
                                    <template v-slot:append>
                                        <v-icon
                                            color="#C5CEE0"
                                            class="ma-0"
                                        >
                                            {{ mdiMagnify }}
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="12"
                        class="text-right pt-0"
                    >
                        <v-btn
                            class="no-uppercase rounded-lg os-13-sb mr-1"
                            color="#2E567A"
                            text
                            @click="clearFilters"
                        >
                            Clear Filters
                        </v-btn>
                        <!-- Search Button -->
                        <v-btn
                            class="white--text no-uppercase rounded-lg os-13-sb"
                            color="#FFA858"
                            @click="filterServices"
                        >
                            <span v-if="!$store.state.general.customBreakpoints.xxs">Filter Services</span>
                            <v-icon
                                class="ml-1"
                            >
                                {{ mdiFilterPlusOutline }}
                            </v-icon>
                        </v-btn>
                    <!-- Search Button -->
                    </v-col>
                </v-row>
                <!-- Filters -->

                <!-- Card View -->
                <v-row
                    v-if="selectedView === 'card'"
                >
                    <!-- No Professional Services -->
                    <v-col
                        v-if="items.length === 0 && !isLoading"
                        class="os-12-r text-center"
                        style="color: rgba(0, 0, 0, 0.38);"
                    >
                        {{ noDataText }}
                    </v-col>
                    <!-- No Professional Services -->

                    <!-- Professional Services -->
                    <v-col
                        cols="12"
                        md="4"
                        lg="3"
                        class="pa-lg-1 pa-xl-3"
                        v-for="(item, index) in items"
                        :key="index"
                        v-else
                    >
                        <!-- Search/Favourite Card -->
                        <work-professional-service-general-card-component
                            :item="item"
                            :isFavourite="category === 'favourite' || item.is_favourite === 1"
                            :isRequested="true"
                            @favouriteProfessionalService="favouriteProfessionalService"
                            @confirmRequestService="confirmRequestService"
                        />
                        <!-- Search/Favourite Card -->
                    </v-col>
                    <!-- Professional Services -->

                    <v-col
                        v-if="isLoading"
                        class="os-12-r text-center"
                        style="color: rgba(0, 0, 0, 0.38);"
                        cols="12"
                    >
                        Loading professional services...
                        <br />
                        <br />
                        <v-progress-circular
                            indeterminate
                            color="#FFA858"
                        />
                    </v-col>
                </v-row>
                <!-- Card View -->

                <!-- List View -->
                <v-row
                    v-if="selectedView === 'list'"
                >
                    <!-- Heading & Search -->
                    <v-col
                        cols="12"
                        class="pb-0 pt-10"
                    >
                        <div
                            class="os-17-sb px-3"
                            style="background-color: white; height: 60px; display: flex; align-items: center;"
                        >
                            All Services
                            <v-spacer />
                            <div
                                style="width: 275px;"
                            >
                                <v-text-field
                                    dense
                                    hide-details
                                    outlined
                                    color="#2E567A"
                                    placeholder="Search..."
                                    class="os-13-r rounded-lg"
                                    background-color="white"
                                    style="width: 275px;"
                                    v-model="search"
                                >
                                    <template v-slot:append>
                                        <v-icon
                                            color="#C5CEE0"
                                            class="ma-0"
                                        >
                                            {{ mdiMagnify }}
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </div>
                        </div>
                    </v-col>
                    <!-- Heading & Search -->

                    <!-- Services -->
                    <v-col
                        cols="12"
                        class="pt-0"
                    >
                        <v-data-table
                            :headers="tableHeaders"
                            :items="items"
                            item-key="request_id"
                            hide-default-footer
                            disable-pagination
                            :no-data-text="noDataText"
                            :no-results-text="noResultsText"
                            :search="search"
                        >
                            <template v-slot:[`item.name`]="{ item }">
                                {{ item.name }}
                            </template>
                            <template v-slot:[`item.industry_name`]="{ item }">
                                {{ item.industry.name }}
                            </template>
                            <template v-slot:[`item.work_location`]="{ item }">
                                {{ item.work_location.name }}
                            </template>
                            <template v-slot:[`item.rate`]="{ item }">
                                <span v-if="item.interval.id < 4">{{ item.currency.symbol }}{{ item.rate }} per {{ item.interval.name }}</span>
                                <span v-else>Quote Upon Request</span>
                            </template>
                            <template v-slot:[`item.rating`]="{ item }">
                                <v-row>
                                    <v-col
                                        cols="12"
                                        v-if="item.owner.rating"
                                    >
                                        <span>{{ item.owner.rating }}</span>
                                        <v-icon
                                            color="#FFA858"
                                            class="mt-n1"
                                        >
                                            {{ mdiStarOutline }}
                                        </v-icon>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        class="os-10-r"
                                        v-else
                                        style="color: #8F9BB3;"
                                    >
                                        <span>No rating</span>
                                        <v-icon
                                            color="#8F9BB3"
                                            class="mt-n1"
                                        >
                                            {{ mdiStarOutline }}
                                        </v-icon>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at }}
                            </template>
                            <template v-slot:[`item.applicant_unread_messages`]="{ item }">
                                <span v-if="item.applicant_unread_messages">{{ item.applicant_unread_messages }}</span><span v-else>0</span> Message<span v-if="item.applicant_unread_messages !== 1">s</span>
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                                <v-btn
                                    icon
                                    @click="$router.push(`/service/${item.id}/view`)"
                                >
                                    <v-icon
                                        color="#2E567A"
                                        style="opacity: 0.65;"
                                        size="20"
                                    >
                                        {{ mdiArrowRightThinCircleOutline }}
                                    </v-icon>
                                </v-btn>
                                <!-- <v-btn
                                    icon
                                >
                                    <v-icon
                                        color="#2E567A"
                                        style="opacity: 0.65;"
                                        size="20"
                                    >
                                        {{ mdiPrinterOutline }}
                                    </v-icon>
                                </v-btn> -->
                                <v-btn
                                    icon
                                    @click="$router.push(`/service/${item.id}/requested/${item.request_id}`)"
                                >
                                    <v-icon
                                        color="#2E567A"
                                        style="opacity: 0.65;"
                                        size="20"
                                    >
                                        {{ mdiEyeOutline }}
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    icon
                                    @click="favouriteProfessionalService(item)"
                                >
                                    <v-icon
                                        color="#2E567A"
                                        style="opacity: 0.65;"
                                        size="20"
                                        v-if="category === 'all'"
                                    >
                                        {{ mdiHeartOutline }}
                                    </v-icon>
                                    <v-icon
                                        color="#E2574C"
                                        v-else
                                    >
                                        {{ mdiHeart }}
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-col>
                    <!-- Services -->

                    <v-col
                        v-if="isLoading"
                        class="os-12-r text-center"
                        style="color: rgba(0, 0, 0, 0.38);"
                        cols="12"
                    >
                        Loading professional services...
                        <br />
                        <br />
                        <v-progress-circular
                            indeterminate
                            color="#FFA858"
                        />
                    </v-col>
                </v-row>
                <!-- List View -->
            </v-col>
        </v-row>

        <!-- Confirm Request Overlay -->
        <work-professional-service-request-confirm-request-overlay-component
            :showRequestOverlayBoolean="showRequestOverlayBoolean"
            :serviceToRequest="serviceToRequest"
            @toggleRequestOverlayComponent="toggleRequestOverlayComponent"
        />
        <!-- Confirm Request Overlay -->
    </div>
</template>
<script>
    import WorkProfessionalServiceRequestConfirmRequestOverlayComponent from '@/overlays/work/professionalService/request/WorkProfessionalServiceRequestConfirmRequestOverlayComponent.vue'
    import { mdiStarOutline, mdiFilterPlusOutline, mdiEyeOutline, mdiMagnify, mdiArrowRightThinCircleOutline, mdiPrinterOutline, mdiHeartOutline, mdiTextBoxPlusOutline, mdiHeart } from '@mdi/js'
    import { LookupPreferenceController, LookupIndustryController, LookupJobTitleController, LookupJobController, LookupLocationController, WorkProfessionalServiceBoardController, WorkProfessionalServiceFavouriteController } from '@/logic/controller/index.controller.js'
    import GeneralGoogleAutocompleteAddressComponent from '@/components/general/google/GeneralGoogleAutocompleteAddressComponent.vue'
    import WorkProfessionalServiceGeneralCardComponent from '@/components/work/professionalService/general/WorkProfessionalServiceGeneralCardComponent.vue'

    export default {
        name: 'WorkProfessionalServiceDashboardGeneralRequestedPage',

        components: {
            WorkProfessionalServiceRequestConfirmRequestOverlayComponent,
            GeneralGoogleAutocompleteAddressComponent,
            WorkProfessionalServiceGeneralCardComponent
        },

        props: {
            selectedView: {
                type: String,
                required: true
            },

            selectedNavItem: {
                type: String,
                required: true
            }
        },

        watch: {
            selectedNavItem: {
                async handler (value) {
                    let shouldRetrieve
                    if (value === 'Active Services' || value === 'Pending Services' || value === 'History') shouldRetrieve = true
                    else shouldRetrieve = false

                    if (shouldRetrieve) {
                        this.items = []
                        this.mainSearch = ''
                        this.lastPageLoaded = 0
                        this.totalPages = 0
                        this.isLoading = false
                        this.searchIndustry = {
                            id: null
                        }
                        this.searchJobTitle = {
                            id: null
                        }
                        this.searchServiceLocation = {
                            id: null
                        }
                        this.searchCountry = {
                            id: null
                        }
                        this.searchState = null
                        this.searchCity = null
                        this.address = {
                            address_line_1: null
                        }

                        this.getProfessionalServiceCategory()
                        await this.getProfessionalServices()
                    }
                }
            }
        },

        computed: {
            canEnterLocation () {
                if (this.searchServiceLocation) {
                    if (this.searchServiceLocation.id) {
                        if (this.searchServiceLocation.id !== 2) return true
                        else return false
                    } else return true
                } else return true
            },

            locationPlaceholder () {
                if (this.searchServiceLocation) {
                    if (this.searchServiceLocation.id) {
                        if (this.searchServiceLocation.id !== 2) return 'Location'
                        else return 'Select "Onsite" service location'
                    } else return 'Location'
                } else return 'Location'
            },

            tableHeaders () {
                return [
                    {
                        text: 'Service Name',
                        value: 'name'
                    },
                    {
                        text: 'Industry',
                        value: 'industry_name'
                    },
                    {
                        text: 'Location',
                        value: 'work_location'
                    },
                    {
                        text: 'Rate',
                        value: 'rate'
                    },
                    {
                        text: 'Rating',
                        value: 'rating'
                    },
                    {
                        text: 'Requested Date',
                        value: 'created_at'
                    },
                    {
                        text: 'Messages',
                        value: 'applicant_unread_messages'
                    },
                    {
                        text: 'Action',
                        value: 'action'
                    }
                ]
            },

            noResultsText () {
                return 'No results found for: "' + this.search + '"'
            },

            noDataText () {
                return 'No ' + this.category + ' services'
            }
        },

        data () {
            return {
                items: [],
                mdiStarOutline,
                mdiFilterPlusOutline,
                mdiEyeOutline,
                mdiMagnify,
                mdiArrowRightThinCircleOutline,
                mdiPrinterOutline,
                mdiHeartOutline,
                mdiTextBoxPlusOutline,
                mdiHeart,
                showRequestOverlayBoolean: false,
                serviceToRequest: {
                    currency: {},
                    interval: {}
                },
                lookupIndustryController: null,
                lookupJobTitleController: null,
                lookupJobController: null,
                lookupLocationController: null,
                workProfessionalServiceBoardController: null,
                workProfessionalServiceFavouriteController: null,
                industries: [],
                searchIndustry: {
                    id: null
                },
                searchJobTitle: {
                    id: null
                },
                searchServiceLocation: {
                    id: null
                },
                searchCountry: {
                    id: null
                },
                searchState: null,
                searchCity: null,
                countries: [],
                address: {
                    job_id: null,
                    user_id: null,
                    address_line_1: null,
                    google_places_id: null,
                    lat: null,
                    lng: null,
                    state: null,
                    city: null,
                    country: {},
                    timezone: null
                },
                category: null,
                search: '',
                mainSearch: '',
                lastPageLoaded: 0,
                totalPages: 0,
                isLoading: true,
                serviceLocations: []
            }
        },

        beforeMount () {
            this.lookupIndustryController = new LookupIndustryController()
            this.lookupJobTitleController = new LookupJobTitleController()
            this.lookupJobController = new LookupJobController()
            this.lookupLocationController = new LookupLocationController()
            this.lookupPreferenceController = new LookupPreferenceController()
            this.workProfessionalServiceBoardController = new WorkProfessionalServiceBoardController()
            this.workProfessionalServiceFavouriteController = new WorkProfessionalServiceFavouriteController()
        },

        async mounted () {
            this.getIndustries()
            this.getCountries()
            this.getProfessionalServiceCategory()
            this.getLocations()
            await this.getProfessionalServices()

            var self = this
        
            const professionalServiceContainer = document.getElementById('professionalServiceContainer')
            professionalServiceContainer.addEventListener('scroll', async () => {
                if (this.selectedView === 'card') {
                    if (professionalServiceContainer.offsetHeight + professionalServiceContainer.scrollTop + 500 >= professionalServiceContainer.scrollHeight) {
                        if (!self.isLoading && self.lastPageLoaded < self.totalPages) {
                            await this.getProfessionalServices()
                        }
                    }
                } else {
                    if (professionalServiceContainer.offsetHeight + professionalServiceContainer.scrollTop + 120 >= professionalServiceContainer.scrollHeight) {
                        if (!self.isLoading && self.lastPageLoaded < self.totalPages) {
                            await this.getProfessionalServices()
                        }
                    }
                }
            })
        },

        methods: {
            getProfessionalServiceCategory () {
                switch (this.selectedNavItem) {
                case 'Active Services':
                    this.category = 'active'
                    break;
                case 'Pending Services':
                    this.category = 'pending'
                    break;
                case 'History':
                    this.category = 'history'
                    break;
                }
            },

            async getIndustries () {
                try {
                    this.industries = await this.lookupIndustryController.get(true, false, false, false, false, false)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getCountries () {
                try {
                    this.countries = await this.lookupLocationController.getCountries()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getLocations () {
                try {
                    this.serviceLocations = await this.lookupPreferenceController.getWorkLocations()

                    const anyLocationIndex = this.serviceLocations.findIndex(itemToFind => itemToFind.name === 'Any')
                    if (anyLocationIndex > -1) this.serviceLocations.splice(anyLocationIndex, 1)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async filterServices () {
                this.lastPageLoaded = 0
                this.items = []
                await this.getProfessionalServices()
            },

            async clearFilters () {
                this.lastPageLoaded = 0
                this.items = []
                this.searchIndustry = {
                    id: null
                }
                this.searchJobTitle = {
                    id: null
                }
                this.searchServiceLocation = {
                    id: null
                }
                this.searchCountry = {
                    id: null
                }
                this.searchState = null
                this.searchCity = null
                this.mainSearch = ''
                this.address = {
                    address_line_1: null
                }
                await this.getProfessionalServices()
            },

            clearServiceLocation () {
                this.searchServiceLocation = {
                    id: null
                }
                this.searchCountry = {
                    id: null
                }
                this.searchState = null
                this.searchCity = null

                this.address = {
                    address_line_1: null
                }
            },

            clearMainSearch () {
                this.mainSearch = ''
            },

            async getProfessionalServices () {
                try {
                    this.isLoading = true

                    let query = {}

                    let industryID = null
                    if (this.searchIndustry) {
                        if (this.searchIndustry.id) {
                            industryID = this.searchIndustry.id
                            query.industryID = industryID
                        }
                    }

                    let jobTitleID = null
                    if (this.searchJobTitle) {
                        if (this.searchJobTitle.id) {
                            jobTitleID = this.searchJobTitle.id
                            query.jobTitleID = jobTitleID
                        }
                    }

                    let serviceLocationID = null
                    if (this.searchServiceLocation) {
                        if (this.searchServiceLocation.id) {
                            serviceLocationID = this.searchServiceLocation.id
                            query.serviceLocationID = serviceLocationID
                            if (serviceLocationID === 2) {
                                this.searchState = null
                                this.searchCity = null
                                this.searchCountry = { id: null }

                                this.address = {
                                    address_line_1: null
                                }
                            }
                        } else {
                            this.searchState = null
                            this.searchCity = null
                            this.searchCountry = { id: null }

                            this.address = {
                                address_line_1: null
                            }
                        }
                    } else {
                        this.searchState = null
                        this.searchCity = null
                        this.searchCountry = { id: null }

                        this.address = {
                            address_line_1: null
                        }
                    }

                    let countryID = null
                    if (this.searchCountry) {
                        if (this.searchCountry.id) {
                            countryID = this.searchCountry.id
                            query.countryID = countryID
                        }
                    }

                    if (this.searchState) query.state = this.searchState
                    if (this.searchCity) query.city = this.searchCity

                    // if (Object.keys(query).length > 0) {
                    //     let routeQuery = this.$route.query
                    //     if (Object.keys(routeQuery).length > 0) {
                    //         for(var prop in routeQuery) {
                    //             query[prop] = routeQuery[prop]
                    //         }
                    //     }
                    // } else {
                    //     query.view = this.$route.query.view
                    // }

                    // if (query != this.$route.query) this.$router.replace({ query })

                    this.lastPageLoaded = Number(this.lastPageLoaded) + 1

                    let recordsToRetrieve = 10

                    if (this.selectedView === 'card') recordsToRetrieve = 10
                    else recordsToRetrieve = 20

                    const response = await this.workProfessionalServiceBoardController.retrieve('requested', this.lastPageLoaded, recordsToRetrieve, this.mainSearch, this.category, industryID, jobTitleID, null, serviceLocationID, countryID, this.searchState, this.searchCity)

                    if (response && response.length > 0) {
                        this.totalPages = response[0].page_total
                        for (let index = 0; index < response.length; index++) {
                            const item = response[index]

                            if (item.created_at) {
                                const removeTime = item.created_at.split(' ')[0]
                                const convertDate = new Date(removeTime)
                                const splitConvertedDate = convertDate.toString().split(' ')
                                item.created_at = splitConvertedDate[2] + ' ' + splitConvertedDate[1] + ' ' + splitConvertedDate[3]
                            }

                            this.items.push(item)
                        }
                    }

                    this.isLoading = false

                    this.$nextTick(async () => {
                        const professionalServiceContainer = document.getElementById('professionalServiceContainer')
                        if (response && response.length > 0 && this.lastPageLoaded < this.totalPages && professionalServiceContainer.offsetHeight + professionalServiceContainer.scrollTop === professionalServiceContainer.scrollHeight) await this.getProfessionalServices()
                    })                
                } catch (error) {
                    this.isLoading = false

                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            googlePlaceSelected (place) {
                if (place) {
                    Object.assign(this.address, place)

                    this.searchState = place.state
                    this.searchCity = place.city

                    const countryIndex = this.countries.findIndex(country => country.iso_2 === place.country.country_code)
                    if (countryIndex > -1) this.searchCountry.id = this.countries[countryIndex].id
                } else {
                    this.searchState = null
                    this.searchCity = null
                    this.searchCountry = { id: null }

                    this.address = {
                        job_id: null,
                        user_id: null,
                        address_line_1: null,
                        google_places_id: null,
                        lat: null,
                        lng: null,
                        state: null,
                        city: null,
                        country: {},
                        timezone: null
                    }
                }
            },

            addressChanged (address) {
                this.address.address_line_1 = address
            },

            toggleRequestOverlayComponent (value, confirmRequest) {
                this.showRequestOverlayBoolean = value

                if (confirmRequest) this.requestService(this.serviceToRequest)
            },

            async favouriteProfessionalService (professionalService) {
                try {
                    await this.workProfessionalServiceFavouriteController.toggleFavourite(professionalService.id)

                    const professionalServiceIndex = this.items.findIndex(itemToFind => itemToFind.id === professionalService.id)

                    if (professionalServiceIndex > -1) {
                        if (this.items[professionalServiceIndex].is_favourite === 0) {
                            this.$store.commit('setSnackbar', {
                                text: 'Added to favourites',
                                show: true,
                                color: '#069B34'
                            })

                            this.items[professionalServiceIndex].is_favourite = 1
                        } else if (this.items[professionalServiceIndex].is_favourite === 1) {
                            this.$store.commit('setSnackbar', {
                                text: 'Removed from favourites',
                                show: true,
                                color: '#069B34'
                            })

                            this.items[professionalServiceIndex].is_favourite = 0
                        }
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            professionalServiceDetail (professionalService) {
                this.$router.push(`/job/${professionalService.job_id}/view`)
            },

            confirmRequestService (service) {
                this.serviceToRequest = service
                this.toggleRequestOverlayComponent(true)
            },

            requestService (service) {
                this.$router.push(`/service/${service.id}/request`)
            }
        }
    }
</script>
<style scoped>
    .four-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        white-space: normal;
    }

    .two-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: normal;
    }

    >>> .v-list-item {
        min-height: 0;
        height: 30px;
    }

    >>> th {
        font-size: 15px !important;
        font-weight: 600 !important;
        color: #8F9BB3 !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
    }

    >>> td {
        font-size: 12px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
        max-width: 250px;
    }

    >>> thead {
        background-color: #EFF4FA !important;
    }

    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }
</style>
