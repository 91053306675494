<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-05-10 15:13:50
 * @ Description: Component displaying search and favourite professional service cards.
 -->

<template>
    <v-card
        height="360px"
        width="100%"
        max-width="280px"
        class="rounded-lg pa-3 mx-auto"
    >
        <v-row>
            <v-col
                cols="12"
                style="position: relative;"
            >
                <v-btn
                    icon
                    class="mr-2 pr-4"
                    style="position: absolute;"
                    @click="favouriteProfessionalService()"
                    v-if="!isFavourite"
                >
                    <v-icon
                        color="#2E567A"
                        class="ml-4"
                    >
                        {{ mdiHeartOutline }}
                    </v-icon>
                </v-btn>
                <v-btn
                    icon
                    class="mr-2 pr-4"
                    style="position: absolute;"
                    @click="favouriteProfessionalService()"
                    v-else
                >
                    <v-icon
                        color="#E2574C"
                        class="ml-4"
                    >
                        {{ mdiHeart }}
                    </v-icon>
                </v-btn>
                <v-btn
                    icon
                    aria-label="notifications"
                    style="position: absolute; right: 12px;"
                    v-if="isRequested && unreadMessages > 0"
                >
                    <v-badge
                        overlap
                        color="#FFA858"
                        :content="unreadMessages"
                    >
                        <v-icon
                            color="#2E567A"
                        >
                            {{ mdiBell }}
                        </v-icon>
                    </v-badge>
                </v-btn>
                <v-btn
                    icon
                    aria-label="notifications"
                    style="position: absolute; right: 12px;"
                    v-else-if="isRequested && !unreadMessages"
                >
                    <v-icon
                        color="#2E567A"
                    >
                        {{ mdiBell }}
                    </v-icon>
                </v-btn>
                <div
                    style="display: flex; justify-content: center;"
                >
                    <v-avatar
                        size="64"
                    >
                        <v-img
                            :src="item.image ? item.image : '/general/FixerIndividualIcon.png'"
                            contain
                        />
                    </v-avatar>
                </div>
            </v-col>
            <v-col
                class="os-16-sb text-truncate text-center py-0"
                cols="12"
            >
                {{ item.name }}
            </v-col>
            <v-col
                class="os-12-r three-lines text-center mb-1 four-lines"
                cols="12"
                style="color: #8F9BB3; height: 83px; max-height: 83px; overflow-y: hidden;"
                v-html="item.description"
            />
            <v-col
                cols="12"
                class="pb-0 pt-6"
                style="position: relative;"
            >
                <v-divider />
                <span
                    class="os-12-b"
                    style="position: absolute; top: 0; right: 12px; color: #8F9BB3; cursor: pointer;"
                    @click="readMore"
                >
                    Read more...
                </span>
            </v-col>
            <v-col
                cols="12"
            >
                <div
                    class="os-10-r"
                    style="color: #8F9BB3;"
                >
                    Industry:
                </div>
                <div
                    class="os-12-sb"
                    style="color: #2E567A"
                >
                    <v-row>
                        <v-col
                            cols="6"
                            class="text-truncate"
                        >
                            {{ item.industry.name }}
                        </v-col>
                        <v-col
                            cols="6"
                            class="text-right text-truncate pl-0"
                            style="margin-top: -2px;"
                        >
                            <v-icon
                                color="#2E567A"
                            >
                                {{ mdiMapMarkerOutline }}
                            </v-icon>
                            <span>{{ item.work_location.name }}</span>
                        </v-col>
                    </v-row>
                </div>
                <!-- <div
                    class="os-10-r text-truncate"
                    style="color: #8F9BB3;"
                >
                    Title:
                </div> -->
                <div
                    class="os-12-sb"
                    style="color: #2E567A"
                >
                    <v-row>
                        <!-- <v-col
                            cols="8"
                            class="text-truncate"
                        >
                            {{ item.jobTitle.name }}
                        </v-col> -->
                        <v-col
                            cols="12"
                            class="text-right"
                            v-if="item.owner.rating"
                        >
                            <span>{{ item.owner.rating }}</span>
                            <v-icon
                                color="#FFA858"
                                class="mt-n1"
                            >
                                {{ mdiStarOutline }}
                            </v-icon>
                        </v-col>
                        <v-col
                            cols="12"
                            class="text-right os-10-r"
                            v-else
                            style="color: #8F9BB3;"
                        >
                            <span>No rating</span>
                            <v-icon
                                color="#8F9BB3"
                                class="mt-n1"
                            >
                                {{ mdiStarOutline }}
                            </v-icon>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
            <v-col
                cols="12"
                class="py-0"
            >
                <v-divider />
            </v-col>
            <v-col
                cols="6"
                class="os-12-b text-truncate"
                style="color: #2E567A;"
            >
                <span v-if="item.interval.id < 4">{{ item.currency.symbol }}{{ item.rate }} per {{ item.interval.name }}</span>
                <span v-else>Quote Upon Request</span>
            </v-col>
            <v-col
                cols="6"
                class="text-right os-12-sb"
                style="color: #2E567A; text-decoration: underline; cursor: pointer;"
                v-if="isRequested"
                @click="$router.push(`/service/${item.id}/requested/${item.request_id}`)"
            >
                <span>View Request</span>
            </v-col>
            <v-col
                cols="6"
                class="text-right os-12-sb"
                style="color: #2E567A; text-decoration: underline; cursor: pointer;"
                @click="confirmRequestService"
                v-else
            >
                <span>Request Service</span>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
    import { mdiHeartOutline, mdiHeart, mdiMapMarkerOutline, mdiStarOutline, mdiBell } from '@mdi/js'

    export default {
        name: 'WorkProfessionalServiceGeneralCardComponent',

        props: {
            item: {
                type: Object,
                required: true
            },

            isFavourite: {
                type: Boolean,
                required: true
            },

            isRequested: {
                type: Boolean,
                required: true
            }
        },

        computed: {
            unreadMessages () {
                if (this.$store.state.user.userData.id === this.item.owner.id) return this.item.owner_unread_messages
                else return this.item.applicant_unread_messages
            }
        },

        data () {
            return {
                mdiHeartOutline,
                mdiHeart,
                mdiMapMarkerOutline,
                mdiStarOutline,
                mdiBell
            }
        },

        methods: {
            favouriteProfessionalService () {
                this.$emit('favouriteProfessionalService', this.item)
            },

            confirmRequestService () {
                this.$emit('confirmRequestService', this.item)
            },

            goToCompanyProfile (companyID) {
                this.$router.push(`/profile/company/${companyID}`)
            },

            readMore () {
                if (this.isRequested) this.$router.push(`/service/${this.item.id}/view`)
                else this.$emit('readMore', this.item)
            }
        }
    }
</script>
<style scoped>
    .four-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        white-space: normal;
    }

    .two-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: normal;
    }
</style>
